import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import io from 'socket.io-client'

import { RELYSIA_API_WSS, SERVICE_ID } from '@/constants/environment'
import authSelector from '@/redux/selectors/auth'
import sidebarSelector from '@/redux/selectors/siderbar'
import { getFavourites } from '@/redux/slices/favourites'
import {
  getHistoriesThunk,
  getInternalWalletBalance,
} from '@/redux/slices/wallet'
import SplashLoader from '../splashLoader'

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch()
  const { isAuthenticated, isUserPending, user } = useSelector(authSelector)
  const { leagues } = useSelector(sidebarSelector)
  const socketRef = useRef(null)
  const router = useRouter()
  const { pathname, push } = router

  const dataGetterWithuid = (uid) => {
    if (uid) {
      batch(() => {
        dispatch(getFavourites(uid))
        dispatch(getInternalWalletBalance())
      })
    }
  }

  //  socket to automatically display the added balance after depositing (via paymail)
  const socketInitializer = () => {
    if (!user?.accessToken) return

    socketRef.current = io(`wss://${RELYSIA_API_WSS}`, {
      auth: {
        authToken: user.accessToken,
        serviceId: SERVICE_ID,
      },
      transports: ['websocket', 'polling'],
    })

    // Listen for messages and log them as notification arrive
    socketRef.current.on('notification', function (message) {
      batch(() => {
        dispatch(getInternalWalletBalance())
        dispatch(getHistoriesThunk('1'))
      })
    })

    socketRef.current.on('connect', function (connection) {
      console.log('client Connected')

      socketRef.current.on('close', function () {
        // console.log('echo-protocol Connection Closed')
      })
    })

    socketRef.current.on('error', function (error) {
      // console.log(' Connection error', error.toString())
    })

    socketRef.current.on('disconnect', function (reason) {
      console.log(
        { reason, connected: socketRef.current.connected },
        ' Client closed',
      )
    })
  }

  const shouldInitializeSocket = () => user && !pathname.startsWith('/casino')

  useEffect(() => {
    if (shouldInitializeSocket()) {
      socketInitializer()
    }

    return () => {
      if (socketRef.current && shouldInitializeSocket()) {
        socketRef.current.disconnect()
        socketRef.current = null
      }
    }
  }, [user, pathname])

  useEffect(() => {
    if (!isUserPending) {
      if (user?.uid) {
        dataGetterWithuid(user.uid)
      }
    }
  }, [user, isUserPending])

  const authRoute =
    pathname === '/register' ||
    pathname === '/login' ||
    pathname === '/reset-password'
  const routesWithAuth = pathname.includes('settings')

  useEffect(() => {
    if (routesWithAuth && !isAuthenticated && !isUserPending) {
      push('/login')
    }
  }, [routesWithAuth, isAuthenticated, isUserPending])

  useEffect(() => {
    if (authRoute && isAuthenticated && !isUserPending) {
      push('/sports/popular-events')
    }
  }, [isAuthenticated])

  if (pathname.includes('admin') && typeof window !== 'undefined') {
    if (!isAuthenticated && !isUserPending) {
      push('/home')
    } else if (
      isAuthenticated &&
      user?.roleType !== 'admin' &&
      !isUserPending
    ) {
      push('/home')
    }
  }

  if (
    pathname.includes('admin') &&
    isUserPending &&
    user?.roleType !== 'admin'
  ) {
    return <SplashLoader show={true} />
  }

  if (isUserPending || (!isAuthenticated && routesWithAuth) || !leagues) {
    return <SplashLoader show={true} />
  }

  return <>{children}</>
}

export default ProtectedRoute
