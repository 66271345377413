import React from 'react'
const Wheel = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    width={100}
    height={100}
    xmlSpace="preserve"
    {...props}
  >
    <circle
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      cx="16"
      cy="16"
      r="9"
    />
    <circle
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      cx="16"
      cy="16"
      r="14"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="16"
      y1="2"
      x2="16"
      y2="7"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="10.6"
      y1="3.1"
      x2="12.6"
      y2="7.7"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="6.1"
      y1="6.1"
      x2="9.6"
      y2="9.6"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="3.1"
      y1="10.6"
      x2="7.7"
      y2="12.6"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="2"
      y1="16"
      x2="7"
      y2="16"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="3.1"
      y1="21.4"
      x2="7.7"
      y2="19.4"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="6.1"
      y1="25.9"
      x2="9.6"
      y2="22.4"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="10.6"
      y1="28.9"
      x2="12.6"
      y2="24.3"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="16"
      y1="30"
      x2="16"
      y2="25"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="21.4"
      y1="28.9"
      x2="19.4"
      y2="24.3"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="25.9"
      y1="25.9"
      x2="22.4"
      y2="22.4"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="28.9"
      y1="21.4"
      x2="24.3"
      y2="19.4"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="30"
      y1="16"
      x2="25"
      y2="16"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="28.9"
      y1="10.6"
      x2="24.3"
      y2="12.6"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="25.9"
      y1="6.1"
      x2="22.4"
      y2="9.6"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="21.4"
      y1="3.1"
      x2="19.4"
      y2="7.7"
    />
    <circle
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      cx="16"
      cy="11"
      r="1"
    />
    <circle
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      cx="16"
      cy="16"
      r="1"
    />
    <circle
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      cx="21"
      cy="16"
      r="1"
    />
    <circle
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      cx="11"
      cy="16"
      r="1"
    />
    <circle
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      cx="16"
      cy="21"
      r="1"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="11"
      y1="16"
      x2="21"
      y2="16"
    />
    <line
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="16"
      y1="21"
      x2="16"
      y2="11"
    />
  </svg>
)
export default Wheel
